import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  },
  card:{
    fontSize:24
  },
  link:{
    color:"red"
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='The future belongs to those who believe in the beauty of their dreams.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            {/* <Button sx={styles.button}>Contact Me</Button> */}
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            {/* <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  ac elit nec felis faucibus pellentesque in sit amet neque.
                </Text>
                <Text variant='p'>
                  Nulla est elit, feugiat et lacinia nec, aliquam sit amet est.
                  Ut in consequat ipsum, in sagittis felis.
                </Text>
                <Text variant='p'>
                  Phasellus arcu mauris, fermentum ac dui quis, blandit pharetra
                  orci. lacus et eros suscipit malesuada. Suspendisse hendrerit
                  leo vitae. Sed in ipsum porttitor. Pellentesque sollicitudin
                  pharetra.
                </Text>
                <Text variant='p'>
                  Sed rutrum odio eu sapien aliquam, quis blandit mi lobortis.
                  Vivamus venenatis sem eu ante laoreet facilisis:
                </Text>
                <Text variant='p'>
                  <ul>
                    <li>Cotidieque reformidans</li>
                    <li>Mucius sensibus</li>
                    <li>Sit primis iudicabit</li>
                  </ul>
                </Text>
                <Text variant='p'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s. Fusce odio dolor,
                  rhoncus ac purus id, bibendum malesuada neque.
                </Text>

                <Text variant='p'>
                  At sit consul aperiri omittam ullum. Usu ut option tibique
                  maluisset, ornatus cum ad, pri tale cotidieque reformidans ut.
                </Text>
                <Text variant='p'>
                  Eum ludus iudico ne. Vel labitur habemus vituperata vix!
                </Text>
              </Section>
            </Box>
            */}
           
            <Box sx={styles.column}>
              <Section title="Where (do I live/am I from)?">
                <Card variant='paper' sx={styles.card}>
                  I'm Djibouti native, living in Saudi Arabia.
                </Card>
              </Section>
              <Divider />
              <Section title='What (do I do)?'>
                <Card variant='paper' sx={styles.card}>
                A full-stack engineer transitioning to solution architect.
             Currently, I work at Saudi Aramco Entrepreneurship Ventures as an application developer and systems analyst.
                  I also work with the startup incubation team as a tech advisor.
                </Card>
              </Section>
              <Divider />
              <Section title='What else (do I do)?'>
                <Card variant='paper' sx={styles.card}>
                  Outside of my day job, I'm a co-founder and full-stack engineer at  
                  <a sx={styles.link} href="https://opeta.io/" target='_blank' aria-label='opeta'>{' '}Opeta.io{' '}</a> 
                    a product that provides reliable in-store analytics for retailers using AI.
                </Card>
              </Section>
              <Divider />
              <Section title='What (I like do)?'>
                <Card variant='paper' sx={styles.card}>
                build products that are powered by technology and innovation that aim to solve real customer problems.
                Always continue learning how to create the technology and reach the target market.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
